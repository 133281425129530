import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import Header from './Components/Header';
import Bodha from './Components/Bodha';
import AboutUs from './Pages/AboutUs/AboutUs';
import Construction from './Pages/Construction/Construction';
import ResaleProjects from './Pages/ResaleProjects/ResaleProjects';
import InteriorDesign from './Pages/InteriorDesign/InteriorDesign';
import OngoingProjects from './Pages/OngoingProjects/OngoingProjects';
import Footer from './Components/Footer';
import ScrollToTop from './Components/ScrollToTop';
import Blog from './Pages/Blog/Blog';
import LandingPage from './Pages/LandingPage/LandingPage';
import LandingStrip from './Components/LandingStrip';
import { useEffect } from 'react';
import AgricultureLands from './Pages/AgricultureLands/AgricultureLands';
import toast, { Toaster } from 'react-hot-toast';
import ThankyouPage from './Components/ThankyouPage';

// import {useLocation} from "react-router-dom"

function App() {
  // useEffect(() => {
  //   console.log(window.location.pathname)
  // }, []);

  // const location = useLocation()
  // console.log(location.pathname)

  // const routerloaction = window.location.pathname;

  return (
    <div className="App">
      <Router>
        {/* {routerloaction == "/landingpage" ? <LandingStrip /> : null} */}
        <Route path={"/freewebinar"} exact component={LandingStrip} />
        <ScrollToTop>
          <Switch>
            <Route path={"/"} exact component={Bodha} />
            <Route path={"/about"} exact component={AboutUs} />
            <Route path={"/construction"} exact component={Construction} />
            <Route path={"/hummingwoods"} exact component={ResaleProjects} />
            <Route path={"/interiordesigns"} exact component={InteriorDesign} />
            <Route path={"/ongoingprojects"} exact component={OngoingProjects} />
            <Route path={"/blog"} exact component={Blog} />
            <Route path={"/freewebinar"} exact component={LandingPage} />
            <Route path={"/agriculturelands"} exact component={AgricultureLands} />
            <Route path={"/thankyou"} exact component={ThankyouPage} />            
          </Switch>
        </ScrollToTop>
      </Router>

    <Toaster
      position="top-center"
      reverseOrder={false}
    />
    </div>
  );
}

export default App;
