import React from "react";
import { Link, NavLink } from "react-router-dom";

const Header = () => {

    return <div>
        <div className="header-sec">
            <div className="header-sec-inner px-4">
                <div className="row">
                    <div className="col-md-3 col-sm-4 column-500">
                        <Link to="/">
                            <div className="header-logo">
                                <img src={require("../Components/images/logo2.png")} alt="" />
                            </div>
                        </Link>
                    </div>
                    <div className="col-md-6 col-sm-4 column-500 d-flex align-items-center justify-content-end layout-end">
                        <div className="header-menu">
                            <nav className="navbar navbar-expand-lg navbar-light">
                                <button className="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon icon"><i id="open-menu" className="fa fa-bars"
                                        aria-hidden="true"></i><i id="close-menu" className="fa fa-close  "
                                            style={{ display: "none" }} aria-hidden="true"></i></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav d-flex justify-content-between align-items-center w-100   ">
                                        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                            <NavLink className="nav-link" to="/about">About Us</NavLink>
                                        </li>
                                        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                            <NavLink className="nav-link display-links" to="/ongoingprojects">On Going Projects</NavLink>
                                        </li>
                                        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                            <NavLink className="nav-link" to="/hummingwoods">Humming Woods </NavLink>
                                        </li>
                                        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                            <NavLink className="nav-link" to="/construction">Construction</NavLink>
                                        </li>
                                        {/* <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                            <NavLink className="nav-link display-links">Interior Designs </NavLink>
                                        </li> */}
                                        {/* <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                            <Link className="nav-link" to="/blog">Blog </Link>
                                        </li> */}
                                        <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                            <NavLink className="nav-link" to="/agriculturelands">Agriculture </NavLink>
                                        </li>
                                        <li className="nav-item phone-link-no d-none" data-toggle="collapse" data-target=".navbar-collapse.show">
                                            <a href="tel:(+91) 75699 22847"><i className="fa-solid fa-phone-volume"></i>75699 22847 </a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div className="col-md-2 d-flex justify-content-center align-items-center layout-display">
                        <ul className="phone-number-link">
                            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                <a href="tel:(+91) 75699 22847"><i className="fa-solid fa-phone-volume"></i> (+91) 75699 22847 </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-1 col-sm-4 column-500">
                        <div className="free-webinar">
                            <Link to={"/freewebinar"} target={"_blank"} className="text-white">Free Webinar <i className="fa-solid fa-caret-right ms-1"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div >;
};

export default Header;
