import React from "react";
import './ResaleProjects.css';
import Modal from "react-modal";
import { useState } from "react";
import "../../Components/Modalstyles.css";
import { Link } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

const ResaleProjects = () => {
  // const [isOpen, setIsOpen] = useState(false);
  // function toggleModal() {
  //   setIsOpen(!isOpen);
  // }
  // const [isOpen2, setIsOpen2] = useState(false);
  // function toggleModal2() {
  //   setIsOpen2(!isOpen2);
  // }
  // const [isOpen3, setIsOpen3] = useState(false);
  // function toggleModal3() {
  //   setIsOpen3(!isOpen3);
  // }
  // const [isOpen4, setIsOpen4] = useState(false);
  // function toggleModal4() {
  //   setIsOpen4(!isOpen4);
  // }
  // const [isOpen5, setIsOpen5] = useState(false);
  // function toggleModal5() {
  //   setIsOpen5(!isOpen5);
  // }
  // const [isOpen6, setIsOpen6] = useState(false);
  // function toggleModal6() {
  //   setIsOpen6(!isOpen6);
  // }
  // const [isOpen7, setIsOpen7] = useState(false);
  // function toggleModal7() {
  //   setIsOpen7(!isOpen7);
  // }
  // const [isOpen8, setIsOpen8] = useState(false);
  // function toggleModal8() {
  //   setIsOpen8(!isOpen8);
  // }
  // const [isOpen9, setIsOpen9] = useState(false);
  // function toggleModal9() {
  //   setIsOpen9(!isOpen9);
  // }
  // const [isOpen10, setIsOpen10] = useState(false);
  // function toggleModal10() {
  //   setIsOpen10(!isOpen10);
  // }
  // const [isOpen11, setIsOpen11] = useState(false);
  // function toggleModal11() {
  //   setIsOpen11(!isOpen11);
  // }
  // const [isOpen12, setIsOpen12] = useState(false);
  // function toggleModal12() {
  //   setIsOpen12(!isOpen12);
  // }
  // const [isOpen13, setIsOpen13] = useState(false);
  // function toggleModal13() {
  //   setIsOpen13(!isOpen13);
  // }
  // const [isOpen14, setIsOpen14] = useState(false);
  // function toggleModal14() {
  //   setIsOpen14(!isOpen14);
  // }

  const [isOpen, setIsopen] = useState({
    video1: false,
    video2: false,
    video3: false,
    viseo4: false,
    video5: false,
    video7: false,
    video8: false,
    video9: false,
    video10: false,
    video11: false,
    video12: false,
    viseo13: false,
    video14: false,
  });

  return <div>
    <Header />
    <div className="resale-sec">
      <div>
        <div className="inner-section single-banner">
          <div className="container">
            <h2>Humming Woods</h2>
            <ol className="breadcrumbb">
              {/* <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li> */}
              <li className="breadcrumbb-item active" aria-current="page"> Farms and Retreats</li>
              Balacola Ooty
            </ol>
          </div>
        </div>
      </div>
      <div className="resale-sec-inner">
        <div className="resale-1">
          <div className="resale-1-inner">
            <div className="container">
              <h2 className="text-center mb-1">Humming Woods and Home</h2>
              {/* <p className="text-center mb-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's<br /> standard dummy text ever since the 1500s, when an unknown printer took a galley.</p> */}
              <div className="row">
                <div className="col-md-4">
                  <div className="yt-video-1">
                    <div className="youtube-blk wow animate__fadeInUp">
                      <Modal isOpen={isOpen.video1}
                        onRequestClose={() => { setIsopen({ ...isOpen, video1: false }); }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/vrLFsVeIXJs?si=z3pfI5IMvSfSJXhb" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => { setIsopen({ ...isOpen, video1: false }); }}                                >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img className="curse-p" onClick={() => { setIsopen({ ...isOpen, video1: true }); }} src={require("../../Components/images/Frame 41.jpg")} alt="" />
                      {/* <p>G+1 Villa for Sale at Ameenpur @ 1.45 Cr</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="yt-video-2">
                    <div className="youtube-blk wow animate__fadeInUp" data-wow-delay="0.20s">
                      <Modal isOpen={isOpen.video2}
                        onRequestClose={() => { setIsopen({ ...isOpen, video2: false }); }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/4GYR3-p9tU0?si=HbGdD9c-LhP5nErM" title="Resale DTCP Final approved Plots for Sale at Kadtal. Cheaper Prize. Limited Plots Available" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => { setIsopen({ ...isOpen, video2: false }); }}                                >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img className="curse-p" onClick={() => { setIsopen({ ...isOpen, video2: true }); }} src={require("../../Components/images/Frame 42.jpg")} alt="" />
                      {/* <p>Quarter acre land for sale at Choutuppal</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="yt-video-3">
                    <div className="youtube-blk wow animate__fadeInUp" data-wow-delay="0.30s">
                      <Modal isOpen={isOpen.video3}
                        onRequestClose={() => { setIsopen({ ...isOpen, video3: false }); }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/dN1aKNxuS4k?si=HVxIVRSz8Bqw0FHy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => { setIsopen({ ...isOpen, video3: false }); }}                                >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img className="curse-p" onClick={() => { setIsopen({ ...isOpen, video3: true }); }} src={require("../../Components/images/Frame 43.jpg")} alt="" />
                      {/* <p>Checklist for buying a Flat</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="yt-video-4">
                    <div className="youtube-blk wow animate__fadeInUp" data-wow-delay="0.40s">
                      <Modal isOpen={isOpen.video4}
                        onRequestClose={() => { setIsopen({ ...isOpen, video4: false }); }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/1ZR_hhbZCy0?si=p217vt96uTCI96L8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => { setIsopen({ ...isOpen, video4: false }); }}                                >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img className="curse-p" onClick={() => { setIsopen({ ...isOpen, video4: true }); }} src={require("../../Components/images/Frame 44.jpg")} alt="" />
                      {/* <p>Own a villa for just 71 lacs</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="yt-video-5">
                    <div className="youtube-blk wow animate__fadeInUp" data-wow-delay="0.5s">
                      <Modal isOpen={isOpen.video5}
                        onRequestClose={() => { setIsopen({ ...isOpen, video5: false }); }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/0_ScETZQ8l8?si=I4ueBnkRZOx2NWnt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => { setIsopen({ ...isOpen, video5: false }); }}                                >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img className="curse-p" onClick={() => { setIsopen({ ...isOpen, video5: true }); }} src={require("../../Components/images/Frame 45.jpg")} alt="" />
                      {/* <p>4 Acres land for Sale @ D Nagaram. Choutuppal.</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="yt-video-6">
                    <div className="youtube-blk wow animate__fadeInUp" data-wow-delay="0.60s">
                      <Modal isOpen={isOpen.video6}
                        onRequestClose={() => { setIsopen({ ...isOpen, video6: false }); }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/DD4ozf_4r_Q?si=fUwKL7rpIzRwo4z-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => { setIsopen({ ...isOpen, video6: false }); }}                                >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img className="curse-p" onClick={() => { setIsopen({ ...isOpen, video6: true }); }} src={require("../../Components/images/Frame 46.jpg")} alt="" />
                      {/* <p>318 Sq yards West facing Plot for Sale at Mansanpally</p> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="yt-video-7">
                    <div className="youtube-blk wow animate__fadeInUp" data-wow-delay="0.70s">
                      <Modal isOpen={isOpen.video7}
                        onRequestClose={() => { setIsopen({ ...isOpen, video7: false }); }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/IyIVL_V0Lmc?si=4WAD9CiI_1I3oG9-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => { setIsopen({ ...isOpen, video7: false }); }}>
                            X
                          </button>
                        </div>
                      </Modal>
                      <img className="curse-p" onClick={() => { setIsopen({ ...isOpen, video7: true }); }} src={require("../../Components/images/Frame 47.jpg")} alt="" />
                      {/* <p>Right way to invest in Hyderabad</p> */}
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-4">
                  <div className="yt-video-8">
                    <div className="youtube-blk wow animate__fadeInUp" data-wow-delay="0.80s">
                      <Modal isOpen={isOpen.video8}
                        onRequestClose={() => { setIsopen({ ...isOpen, video8: false }); }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/dzDK32xZQvk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => { setIsopen({ ...isOpen, video8: false }); }}                                >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img className="curse-p" onClick={() => { setIsopen({ ...isOpen, video8: true }); }} src={require("../../Components/images/resale-8.jpg")} alt="" />
                      <p>Business motivation | Josh Talks Telugu</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="yt-video-9">
                    <div className="youtube-blk wow animate__fadeInUp" data-wow-delay="0.90s">
                      <Modal isOpen={isOpen.video9}
                        onRequestClose={() => { setIsopen({ ...isOpen, video9: false }); }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/qKor24JBPKA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => { setIsopen({ ...isOpen, video9: false }); }}                                >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img className="curse-p" onClick={() => { setIsopen({ ...isOpen, video9: true }); }} src={require("../../Components/images/resale-9.jpg")} alt="" />
                      <p>Business motivation | Josh Talks Telugu</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="yt-video-10">
                    <div className="youtube-blk wow animate__fadeInUp" data-wow-delay="1s">
                      <Modal isOpen={isOpen.video10}
                        onRequestClose={() => { setIsopen({ ...isOpen, video10: false }); }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/3Ivo77iZX6A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => { setIsopen({ ...isOpen, video10: false }); }}                                >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img className="curse-p" onClick={() => { setIsopen({ ...isOpen, video10: true }); }} src={require("../../Components/images/resale-10.jpg")} alt="" />
                      <p>Business motivation | Josh Talks Telugu</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="yt-video-11">
                    <div className="youtube-blk wow animate__fadeInUp" data-wow-delay="1.20s">
                      <Modal isOpen={isOpen.video11}
                        onRequestClose={() => { setIsopen({ ...isOpen, video11: false }); }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/512kBsjuf0c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => { setIsopen({ ...isOpen, video11: false }); }}                                >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img className="curse-p" onClick={() => { setIsopen({ ...isOpen, video11: true }); }} src={require("../../Components/images/resale-11.jpg")} alt="" />
                      <p>Business motivation | Josh Talks Telugu</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="yt-video-12">
                    <div className="youtube-blk wow animate__fadeInUp" data-wow-delay="1.30s">
                      <Modal isOpen={isOpen.video12}
                        onRequestClose={() => { setIsopen({ ...isOpen, video12: false }); }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/Mi3rmM-HPfQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => { setIsopen({ ...isOpen, video12: false }); }}                                >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img className="curse-p" onClick={() => { setIsopen({ ...isOpen, video12: true }); }} src={require("../../Components/images/resale-12.jpg")} alt="" />
                      <p>Business motivation | Josh Talks Telugu</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="yt-video-13">
                    <div className="youtube-blk wow animate__fadeInUp" data-wow-delay="1.40s">
                      <Modal isOpen={isOpen.video13}
                        onRequestClose={() => { setIsopen({ ...isOpen, video13: false }); }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/HkRfyv73ZxI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => { setIsopen({ ...isOpen, video13: false }); }}                                >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img className="curse-p" onClick={() => { setIsopen({ ...isOpen, video13: true }); }} src={require("../../Components/images/resale-13.jpg")} alt="" />
                      <p>Business motivation | Josh Talks Telugu</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="yt-video-14">
                    <div className="youtube-blk wow animate__fadeInUp" data-wow-delay="1.5s">
                      <Modal isOpen={isOpen.video14}
                        onRequestClose={() => { setIsopen({ ...isOpen, video14: false }); }}
                        contentLabel="My dialog"
                        overlayClassName="myoverlay"
                        className="mymodal ww">
                        <div className="iframe-outer-sec">
                          <iframe className="iframer-video" src="https://www.youtube.com/embed/CGiNfV_5Swg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        <div className="close-button">
                          <button
                            type="close"
                            className="text-danger"
                            onClick={() => { setIsopen({ ...isOpen, video14: false }); }}                                >
                            X
                          </button>
                        </div>
                      </Modal>
                      <img className="curse-p" onClick={() => { setIsopen({ ...isOpen, video14: true }); }} src={require("../../Components/images/resale-14.jpg")} alt="" />
                      <p>Business motivation | Josh Talks Telugu</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>;
};

export default ResaleProjects;
