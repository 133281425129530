import React, { useState } from "react";
import "./OngoingProjects.css";
import { Link } from "react-router-dom";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Modal from "react-modal";

const OngoingProjects = () => {
  const [isOpened, setIsOpened] = useState({
    videoc1: false,
    videoc2: false,
    videoc3: false,
    videoc4: false,
  });
  return (
    <div>
      <Header />
      <div className="ongoing-projects">
        <div>
          <div className="inner-section single-banner seperate-singlebanner">
            <div className="container">
              <h2>OnGoing Projects</h2>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  OnGoing Projects
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div className="ongoing-projects-inner">
          <div className="ongoing-sec-1">
            <div className="container">
              <div className="row py-5">
                <div className="col-md-6 pt-5">
                  <div className="content-director wow animate__fadeInLeft">
                    <div className="content-heading">
                      <h2>Ms Smitha Krishnamurthy</h2>
                    </div>
                    <p>
                      The Global Food System today is beset by serious
                      challenges and risks, production and prices have become
                      more volatile, hunger and poverty levels remain high,
                      particularly among the farming Communities and
                      unsustainable practices and environmental challenges.
                    </p>
                    <p>
                      Foreseeing all this Ms Smitha Krishnamurthy has taken an
                      initiative to become a part of Food Chain to support the
                      system. Her Vision sets a goal of Improvement in
                      Sustainable farming and Economic Growth achieving these
                      goals with Transformation required in the agri Sector.
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="img-part-sec  wow animate__fadeInRight">
                    <img
                      className="w-100"
                      src={require("../../Components/images/smitha.jpeg")}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="comined-background">
            <div className="ongoing-sec-1 clonned-project-1 py-5">
              <div className="container">
                <div className="project-title mb-5">
                  <h2>About OOTY</h2>
                </div>
                <div className="row ">
                  <div className="col-md-6">
                    <div className="img-part-sec  wow animate__fadeInRight">
                      <img
                        className="w-100"
                        src={require("../../Components/images/beautiful-strawberry-garden-sunrise-doi-ang-khang-chiang-mai-thailand.jpg")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="content-director wow animate__fadeInLeft">
                      <div className="content-heading">
                        <h2>Why in Ooty?</h2>
                      </div>
                      <p>
                        One of the most beautiful Hill Stations in india – The
                        Queen of Hill Stations – Mini Paradise – Ooty. Nilgiris
                        is the name given to a range of mountains spread across
                        the states of Tamilnadu, Karnataka and Kerala 7350 Feet
                        above the Sea level, located at Western Ghats. Ooty is
                        the district headquarters of the Nilgiris District and
                        was first discovered during the British Rule.
                      </p>
                      <p>
                        Nilgiri Hills are aptly called as the blue mountains for
                        the bluish hue.
                      </p>
                      <p>
                        The Elevation of the Nilgiris results in a much cooler
                        and wetter climate than the surrounding plains, so as
                        the area is popular as a comfortable retreat and is good
                        for the cultivation. Summer Temperature hover between 10
                        degree Celsius to 25 degree Celsius and winter chillness
                        is between 8 degrees to 20 degrees or sometimes even
                        lower. While the magic is completed by the beauty of
                        rains for 75 days over the year on an average.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ongoing-sec-2 clonned-project-2">
              <div className="container">
                <div className="project-title ">
                  <h2>The Humming Woods</h2>
                  <h4>An Eco Farming Community</h4>
                </div>
                <div className="row">
                  <div className="col-md-12 hummingorder-1 py-5 fs-5">
                    <p>
                      Own your piece of land and farm your life in healthier way.
                    {/* </p>
                    <p className="mb-1 "> */}
                      Our passion is to promote Natural farming bringing back
                      Eco Based Lifestyle with health and happiness.
                    {/* </p>
                    <p className="mb-1 "> */}
                      We design your life close to nature where your future
                      lies.A life beyond our Imagination.
                    {/* </p>
                    <p className="mb-1 "> */}
                      Breath fresh, Eat Fresh and live fresh.Embrace a life of
                      purity and wellness by Owning a farm plot in the lap of
                      nature.
                    {/* </p>
                    <p className="mb-1 "> */}
                      Available in{" "}
                      <strong className="tobe-bold-22">
                        Quarter, Half & One Acre
                      </strong>{" "}
                      size plots.
                    </p>
                  </div>
                  <div className="col-md-6 hummingorder-2">
                    <div className="project-info wow animate__fadeInRight">
                      <h6>Location Highlights:</h6>
                      <ul className="mb-4">
                        <li>
                          <p>80 Kms from Coimbatore Airport</p>
                        </li>
                        <li>
                          <p>6 kms from Lovedale Railway Station.</p>
                        </li>
                        <li>
                          <p>6 kms from Laurance School</p>
                        </li>
                        <li>
                          <p>On the National Highway Ooty – Coimbatore NH.</p>
                        </li>
                      </ul>
                      <h6>Project Highlights:</h6>
                      <ul className="highlight-points">
                        <li>
                          <img
                            className="enimities-icon"
                            src={require("../../Components/images/property (2).png")}
                            alt=""
                          />
                          <p>25 Acres Secured Community</p>
                        </li>
                        <li>
                          <img
                            className="enimities-icon"
                            src={require("../../Components/images/gate (3).png")}
                            alt=""
                          />
                          <p>Grand Entrance</p>
                        </li>
                        <li>
                          <img
                            className="enimities-icon"
                            src={require("../../Components/images/road (2).png")}
                            alt=""
                          />
                          <p>20 feet external roads</p>
                        </li>
                        <li>
                          <img
                            className="enimities-icon"
                            src={require("../../Components/images/Group (3).png")}
                            alt=""
                          />
                          <p>24/7 Security</p>
                        </li>
                        <li>
                          <img
                            className="enimities-icon"
                            src={require("../../Components/images/electricity (2).png")}
                            alt=""
                          />
                          <p>Common Area Electricity</p>
                        </li>
                        <li>
                          <img
                            className="enimities-icon"
                            src={require("../../Components/images/swings (1).png")}
                            alt=""
                          />
                          <p>Children Paly area</p>
                        </li>
                        <li>
                          <img
                            className="enimities-icon"
                            src={require("../../Components/images/heart (1).png")}
                            alt=""
                          />
                          <p>Wellness Centre</p>
                        </li>
                        <li>
                          <img
                            className="enimities-icon"
                            src={require("../../Components/images/deck-chair (1).png")}
                            alt=""
                          />
                          <p>2 Acres Resort</p>
                        </li>
                        <li>
                          <img
                            className="enimities-icon"
                            src={require("../../Components/images/fence.png")}
                            alt=""
                          />
                          <p>Solar Fenced</p>
                        </li>
                        <li>
                          <img
                            className="enimities-icon"
                            src={require("../../Components/images/cultivation (1).png")}
                            alt=""
                          />
                          <p>Plantation</p>
                        </li>
                        <li>
                          <img
                            className="enimities-icon"
                            src={require("../../Components/images/Vector (2).png")}
                            alt=""
                          />
                          <p>24/7 Surveillance</p>
                        </li>
                        <li>
                          <img
                            className="enimities-icon"
                            src={require("../../Components/images/Group (4).png")}
                            alt=""
                          />
                          <p>Restaurant</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6 hummingorder-3">
                    {/* <div className="img-part-sec  wow animate__fadeInLeft">
                      <img
                        className="w-100"
                        src={require("../../Components/images/india-fog-tea-cool-tea-leaves.jpg")}
                        alt=""
                      />
                    </div> */}
                    <div className="double-img-blk">
                    <div className="image-one wow animate__fadeInLeft  ">
                      <img
                        className="w-100"
                        src={require("../../Components/images/Elevation.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="image-22  wow animate__fadeInRight ">
                      <img
                        className="w-100"
                        src={require("../../Components/images/gate.jpg")}
                        alt=""
                      />
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="ongoing-sec-2">
            <div className="container">
              <div className="row pt-5">
                <div className="col-md-6">
                  <div className="double-img-blk">
                    <div className="image-one wow animate__fadeInLeft  ">
                      <img
                        className="w-100"
                        src={require("../../Components/images/Elevation.jpg")}
                        alt=""
                      />
                    </div>
                    <div className="image-22  wow animate__fadeInRight ">
                      <img
                        className="w-100"
                        src={require("../../Components/images/gate.jpg")}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="project-info wow animate__fadeInRight">
                    <p>
                      One of the most beautiful Hill Stations in india – The
                      Queen of Hill Stations – Mini Paradise – Ooty. Nilgiris is
                      the name given to a range of mountains spread across the
                      among the states of Tamilnadu, Karnataka and Kerala 7350
                      Feet above the Sea level, located at Western Ghats. Ooty
                      is the district headquarters of the Nilgiris District and
                      was first discovered during the British Rule. Nilgiri
                      Hills are aptly called as the blue mountains for the
                      bluish hue.
                    </p>
                    <p>
                      The Elevation of the Nilgiris results in a much cooler and
                      wetter climate than the surrounding plains, so as the area
                      is popular as a comfortable retreat and is good for the
                      cultivation. Summer Temperature hover between 10degrees
                      Celsius to 25 degrees Celsius and winter chillness is
                      between 8 degrees to 20 degrees or sometimes even lower.
                      While the magic is completed by the beauty of rains for 75
                      days over the year on an average.
                    </p>
                    <div className="download-brochure">
                    <a href="../../Components/images/doccuments/broucher material.docx" download="ooty_brochure.docx">Download Brochure</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default OngoingProjects;
